<template>
  <div class="screenshot-slider__wrapper" :class="{'active': hasImages}">
    <div class="no-print screenshot-slider _scr-item swiper" ref="swiper">
      <div class="swiper-wrapper">
        <a v-for="image of images" :data-src="image"
           class="screenshot-slider__slide _gallery-link swiper-slide"
        >
          <div class="slide-image _ibg">
            <img :src="image" alt="kendoo">
          </div>
        </a>
      </div>
    </div>
    <div class="no-print page-slider__nav _container">
      <div class="page-slider__progressbar">
        <div class="screenshot-slider-1__progressbar"
             ref="progressbar"></div>
      </div>
      <div class="page-slider__buttons">
        <div class="page-slider__prev-button screenshot-slider-1__prev
             _icon-arrow-1"
             ref="prevSlideButton"></div>
        <div class="page-slider__next-button screenshot-slider-1__next
             _icon-arrow-1"
             ref="nextSlideButton"></div>
      </div>
    </div>

    <div class="print-only">
      <img :src="image" alt="kendoo" v-for="image of images">
    </div>

  </div>

</template>


<script>
import Swiper from '@/assets/js/Swiper';
import '@/assets/js/lightGallery';

export default {
    name: "ScreenshotSlider",
    props: {
        images: Array
    },

    data() {
        return {
            swiper: null
        }
    },

    mounted() {
        if ( this.images && this.images.length > 0 ) {
            this.initSlider();
        }
    },

    computed: {
        hasImages() {
            return this.images && this.images.length > 0
        }
    },

    methods: {
        initSlider() {
            this.swiper = new Swiper(this.$refs.swiper, {
                speed: 900,
                observer: true,
                observeParents: true,
                spaceBetween: 136,
                slidesPerView: 1,
                centeredSlides: true,
                initialSlide: 0,
                grabCursor: true,
                slideActiveClass: '_active-slide',
                navigation: {
                    prevEl: this.$refs.prevSlideButton,
                    nextEl: this.$refs.nextSlideButton,
                    disabledClass: '_disabled',
                },
                pagination: {
                    el: this.$refs.progressbar,
                    type: 'progressbar',
                },
                breakpoints: {
                    320: {
                        spaceBetween: 16,
                    },
                    768: {
                        spaceBetween: 136,
                    }
                }
            });
            setTimeout(() => {
                window.lightGallery(this.$refs.swiper, {
                    counter: false,
                    selector: 'a',
                    download: false
                });
            }, 0)
        }
    }
}
</script>
