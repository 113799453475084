<template>
    <section class="leaderboard-section">
        <div class="section-title">
            <div class="section-title__line"></div>
            <div class="section-title__title">LEADERBOARD</div>
            <div class="section-title__line"></div>
        </div>

        <TournamentLeaderboardComponent
            :leaderboard-data="leaderboardData"
            :date-locale="dateLocale"
            :date-time-zone="dateTimeZone"
        />

    </section>
</template>

<script>

import TournamentLeaderboardComponent from "@/pages/tournament/components/tournament_leaderboard_component";


export default {
    name: "TournamentLeaderboardSection",
    props: {
        leaderboardData: {
            type: Array,
            default: [],
        },
        dateLocale: {
            type: String,
            default: undefined,
        },
        dateTimeZone: {
            type: String,
            default: undefined,
        }
    },
    components: {
        TournamentLeaderboardComponent,
    }
}
</script>

