<template>
  <main objname="languages" class="games-grid">
    <SummaryGrid
        v-if="gridOptions !== null"
        :options="gridOptions"
        :user="user" />
  </main>
</template>


<script>
import { appFetch } from '@/lib/request_utils';
import SummaryGrid from './grid';
import {isMobile} from "@/lib/browser";

const useFixedColumns = !isMobile.any();

let fullSchema = [
    {
        "name": "uid",
        "type": "text",
        "title": "pk",
        "width": "0"
    }, {
        "name": "game_id",
        "type": "text",
        "title": "Game ID",
        "cssClass": "summary-left-align-column",
        "width": "200"
    }, {
        "name": "platform_game_id",
        "type": "text",
        "title": "Platform Game ID",
        "width": "100"
    }, {
        "name": "en",
        "type": "color_marked",
        "cssClass": "summary-left-align-column",
        "title": "en",
        "width": "150",
        "fixed": useFixedColumns
    }, {
        "name": "zh",
        "type": "color_marked",
        "title": "zh",
        "width": "150",
        "cssClass": "summary-left-align-column"
    }, {
        "name": "zh-hant",
        "type": "color_marked",
        "title": "zh-hant",
        "width": "150",
        "cssClass": "summary-left-align-column",
    }, {
        "name": "th",
        "type": "color_marked",
        "cssClass": "summary-left-align-column",
        "title": "th",
        "width": "150"
    }
]


export default {
    name: 'SummaryTabLanguages',
    components: {
        SummaryGrid
    },
    props: {
        user: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            tab: 'games',
            gridOptions: null
        }
    },
    methods: {
        loadLanguages() {
            appFetch(`/summary/languages/`, {
                method: 'GET',
                credentials: 'include',
                params: {
                }
            }).then(
                res => res.json()
            ).then(res => {
                let schema = this.prepareSchema(res.data);
                this.gridOptions = {...{
                    title: 'KENDOO',
                    csvFileName: 'languages',
                    pkColumn: 'uid',
                    fixedColumnsNumber: useFixedColumns? 3 : 0,
                    activeTab: 'languages',
                    gridID: 'languages'
                }, ...schema};
            }).catch(res => {
                console.log('------------ TODO GET GAME HANDLE ERROR!', res)
            });
        },

        prepareSchema(res) {
            let allowedSchema = [],
                registeredColumns = [],
                nestedColumns = [];

            fullSchema.forEach(c => {
                if (res.columns.indexOf(c.name) > -1) {
                    allowedSchema.push({...{}, ...c});
                    registeredColumns.push(c.name);
                    nestedColumns.push({
                        colspan: 1,
                        title: "&nbsp;",
                        width: c.width
                    }) ;
                }
            });

            res.columns.forEach(c => {
                if(registeredColumns.indexOf(c) < 0) {
                    allowedSchema.push({
                      type: 'color_marked',
                      width: 50,
                      name: c,
                      title: c
                    });
                    nestedColumns.push({
                        colspan: 1,
                        title: "&nbsp;",
                        width: 50
                    }) ;
                }
            })

            return {
                columns: allowedSchema,
                nestedHeaders: nestedColumns,
                data: res.rows
            }
        }
    },
    mounted() {
        this.loadLanguages();
    },
    computed: {

    },
}
</script>
