<template>
    <div class="time-plate">
        <div v-if="isTournamentNew" class="time-plate__wrapper">
            <div class="time-plate__header">
                <div>
                    <img src="@/assets/img/tournaments/demo-stand/time-plate-start-icon.svg"/>
                    <span>Start</span>
                </div>
                <div>
                    <img src="@/assets/img/tournaments/demo-stand/time-plate-finish-icon.svg"/>
                    <span>End</span>
                </div>
            </div>
            <div class="time-plate__time-range">
                <div>
                    <span>
                        <DateWidget
                            :iso-date-string="tournament.start_at"
                            :options="{day: 'numeric', month: 'numeric', year: 'numeric'}"
                            :locale="dateLocale"
                            :use-time-zone="dateTimeZone"
                        />
                    </span>
                    <span>
                        <DateWidget
                            :iso-date-string="tournament.start_at"
                            :options="{hour: '2-digit', minute: '2-digit'}"
                            :locale="dateLocale"
                            :only-time="true"
                            :use-time-zone="dateTimeZone"
                        />
                    </span>
                    <span>
                        <DateWidget
                            :iso-date-string="tournament.start_at"
                            :options="{timeZoneName: 'short'}"
                            :locale="dateLocale"
                            :only-time-zone="true"
                            :use-time-zone="dateTimeZone"
                        />
                    </span>
                </div>
                <div>
                    <span>
                        <DateWidget
                        :iso-date-string="tournament.end_at"
                            :options="{day: 'numeric', month: 'numeric', year: 'numeric'}"
                            :locale="dateLocale"
                            :use-time-zone="dateTimeZone"
                        />
                    </span>
                    <span>
                        <DateWidget
                            :iso-date-string="tournament.end_at"
                            :options="{hour: '2-digit', minute: '2-digit'}"
                            :locale="dateLocale"
                            :only-time="true"
                            :use-time-zone="dateTimeZone"
                        />
                    </span>
                    <span>
                        <DateWidget
                            :iso-date-string="tournament.end_at"
                            :options="{timeZoneName: 'short'}"
                            :locale="dateLocale"
                            :only-time-zone="true"
                            :use-time-zone="dateTimeZone"
                        />
                    </span>
                </div>
            </div>
        </div>
        <div v-if="isTournamentInProgress" class="time-plate__wrapper">
            <div class="time-plate__header">
                <div>
                    <img src="@/assets/img/tournaments/demo-stand/time-plate-finish-icon.svg"/>
                    <span>Ends in</span>
                </div>
            </div>
            <div class="time-plate__timer">
                <span v-if="timer">
                    <b>{{ String(timerData.days * 24 + timerData.hours).padStart(2, '0') }}</b>:<b>{{ String(timerData.minutes).padStart(2, '0') }}</b>:<b>{{ String(timerData.seconds).padStart(2, '0') }}</b>
                </span>
            </div>
        </div>
        <div v-if="isTournamentFinished" class="time-plate__wrapper">
            <div class="time-plate__header">
                <div>
                    <img src="@/assets/img/tournaments/demo-stand/time-plate-finish-icon.svg"/>
                    <span>Finished</span>
                </div>
            </div>
            <div class="time-plate__timer">
                <span>
                    <DateWidget
                        :iso-date-string="tournament.end_at"
                        :options="{day: 'numeric', month: 'numeric', year: 'numeric'}"
                        :locale="dateLocale"
                        :use-time-zone="dateTimeZone"
                    />
                </span>
            </div>
        </div>
    </div>
</template>

<script>

import DateWidget from "@/components/date_widget";

export default {
    name: "TournamentTimePlateComponent",
    components: {
        DateWidget,
    },
    props: {
        tournament: Object,
        dateLocale: {
            type: String,
            default: undefined,
        },
        dateTimeZone: {
            type: String,
            default: undefined,
        }
    },

    data() {
        return {
            timerData: {
                total: 0,
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
                expired: false,
            },
            timer: undefined
        }
    },

    computed: {
        isTournamentNew() {
            const tournamentStatus = this.tournament?.status;
            return tournamentStatus === 'NEW'
                || tournamentStatus === 'PRE_NOTIFICATION';
        },

        isTournamentInProgress() {
            const tournamentStatus = this.tournament?.status;
            return tournamentStatus === 'STARTED' && !this.timerData.expired;
        },

        isTournamentFinished() {
            const tournamentStatus = this.tournament?.status;
            return tournamentStatus === 'EXPIRED'
                || tournamentStatus === 'POST_PROCESSING'
                || tournamentStatus === 'POST_NOTIFICATION'
                || tournamentStatus === 'FINISHED'
                || (tournamentStatus === 'STARTED' && this.timerData.expired);
        },
    },

    watch: {
        'tournament.status'(oldVal, newVal) {
            this.checkTimer();
        }
    },

    mounted() {
        this.checkTimer();
    },

    methods: {
        updateTimerData() {
            const total = Math.max(Date.parse(this.tournament.end_at) - Date.now(), 0);
            if (total > 0) {
                const days = Math.floor(total / (1000 * 60 * 60 * 24));
                const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
                const minutes = Math.floor((total / 1000 / 60) % 60);
                const seconds = Math.floor((total / 1000) % 60);
                this.timerData = {total, days, hours, minutes, seconds};
            } else if (!this.timerData.expired) {
                const timerData = this.timerData;
                timerData.total = 0;
                timerData.days = 0;
                timerData.hours = 0;
                timerData.minutes = 0;
                timerData.seconds = 0;
                timerData.expired = true;
            }
        },

        checkTimer() {
            if (this.isTournamentInProgress && !this.timer) {
                this.updateTimerData();
                this.timer = setInterval(() => {
                    if (!this.timerData.expired) {
                      this.updateTimerData();
                    } else {
                        clearInterval(this.timer);
                    }
                }, 1000);
            }
        },
    }


}
</script>

<style scoped>

</style>