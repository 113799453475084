<template>
    <section class="rules-section" v-if="ruleBlocks.length > 0">
        <div class="rules-section__rules-grid">
            <div
                v-for="item in ruleBlocks"
                class="rules-section__rules-grid__item"
            >
                <div  class="rules-section__rules-grid__item__icon">
                    <img v-if="item.iconUrl" :src="item.iconUrl" />
                    <img v-else src="@/assets/img/tournaments/demo-stand/rules-default-icon.svg" />
                </div>
                <div class="rules-section__rules-grid__item__text" v-html="item.description"></div>
            </div>
        </div>

    </section>
</template>

<script>
export default {
    name: "TournamentRulesSection",
    props: {
        tournament: {},
    },

    computed: {
        ruleBlocks () {
            const tournament = this.tournament || {};
            const data = [];
            let description;
            for (let i = 1; i <= 4; i++) {
                description = tournament[`rule_block_${i}_description`];
                if (description) {
                    data.push(
                        {
                            iconUrl: tournament[`rule_block_${i}_icon_url`],
                            description: description,
                        }
                    );
                }
            }
            return data;
        }
    }

}
</script>

