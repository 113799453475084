<template>
  <div class="move-lines__title"><p>INTEGRATION DOCUMENTATION</p></div>

  <div class="offset-section">
    <div class="_container">
      <div class="pdf-card-container touch-slider _swiper" ref="swiper">
        <div class="swiper-wrapper">
          <DocumentCard
              :title="documentTitle"
              :url="documentURL"
              :show-card-title="true"
              class="swiper-slide"
              icon-class="_icon-arrow-3"
              target="_blank"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {isMobile, isMozilla} from "@/lib/browser";

import Swiper from "@/assets/js/Swiper";
import DocumentCard from "@/components/document_card";

export default {
    name: "IntegrationDocumentationSection",
    components: {
        DocumentCard,
    },

    data() {
        return {
            swiper: null,
            documentURL: 'https://public-docs.kendoo.com/?ts=' + (new Date()).getTime(),
            documentTitle: "Provider Docs",
        }
    },

    mounted() {
        this.initSlider();
    },

    methods: {
        initSlider() {
            let swiper = new Swiper(
                this.$refs.swiper, {
                    init: false,
                    observer: true,
                    observeParents: true,
                    slidesPerView: 'auto',
                    simulateTouch: true,
                    grabCursor: true,
                });

            // TODO: research
            const matchMedia = window.matchMedia('(max-width: 1144px)')
            matchMedia.addListener(() => {
                swiper.init()
            })
            if (window.innerWidth <= 1144) {
                swiper.init()
            }
            if (isMobile.any()) {
                swiper.init()
            }
            this.swiper = swiper;
        }
    }
}

</script>