<template>
  <main class="page not-found-page">
    <div class="_container">
      <div class="not-found-page__wrapper">
        <img src="@/assets/img/svg/500.svg" alt="kendoo">
        <h4>Oops! Something went wrong.</h4>
        <p>We will work on fixing that right away.
          Meanwhile, you may return to main page.</p>
        <a href="https://kendoo.com" class="page-button">
          <div></div><div>BACK TO HOME</div><div></div>
        </a>
      </div>
    </div>
  </main>
</template>


<script>

export default {
    name: 'Error500',
    components: {},
}
</script>
