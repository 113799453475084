<template>
    <div class="leaderboard-table">
        <div class="fixed-header">
          <table class="data-table">
            <thead>
            <tr>
              <th></th>
              <th></th>
              <th>#</th>
              <th>Player</th>
              <th>Score</th>
              <th>Prize</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="item in leaderboardData"
                :class="{top3: item.place <= 3, top10: item.place >= 4 && item.place <= 10}">
              <td></td>
              <td>
                <img v-if="item.place === 1" src="@/assets/img/tournaments/medal-1.png"/>
                <img v-if="item.place === 2" src="@/assets/img/tournaments/medal-2.png"/>
                <img v-if="item.place === 3" src="@/assets/img/tournaments/medal-3.png"/>
                <img v-if="item.place >= 4 && item.place <= 10" src="@/assets/img/tournaments/medal-common.png"/>
              </td>
              <td>{{ item.place }}</td>
              <td>{{ item.nick }}</td>
              <td>{{ item.score }}</td>
              <td>
                <TournamentPrizeComponent
                    :prize="item.prize"
                    :date-locale="dateLocale"
                    :date-time-zone="dateTimeZone"
                />
              </td>
              <td></td>
            </tr>
            </tbody>
          </table>
        </div>
    </div>
</template>

<script>
import TournamentPrizeComponent from "@/pages/tournament/components/tournament_prize_component";

export default {
    name: "TournamentLeaderboardComponent",

    components: {
        TournamentPrizeComponent,
    },

        props: {
            leaderboardData: {
                type: Array,
                default: [],
            },
            dateLocale: {
                type: String,
                default: undefined,
            },
            dateTimeZone: {
                type: String,
                default: undefined,
            }
        },
}
</script>

<style scoped>

</style>