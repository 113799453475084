<template>
  <main class="page game-auth-page">
    <div class="page__body game-auth-page__body">

      <div class="first-screen first-screen_full offset-section_small">
        <div class="_container">
          <h6 class="print-only offset-title" v-html="item.title_html"></h6>
          <!-- BREAD CRUMBS ============================================= -->
          <BreadCrumbs
              :links="[{title: 'Games', href: '/client-area/games'},
                       {title: item.title_text}]"
          />
          <!-- BREAD CRUMBS ========================================= END -->

          <!-- TITLE ==================================================== -->
          <div class="first-screen__body no-print">
            <div class="first-screen__left">
              <div class="page-heading">
                <h1>
                  <span v-html="item.title_html"></span>
                </h1>
              </div>
            </div>
            <div class="first-screen__right"></div>
          </div>
          <!-- TITLE ================================================ END -->
        </div>
      </div>

      <!-- GAME INFO ==================================================== -->
      <div class="_container">
        <div class="game-auth-page__info">
          <div class="game-data">
            <div v-if="item.game_id">
              <span>Game ID: </span>
              <span>{{ item.game_id }}</span>
            </div>
            <div v-if="item.platform_game_id">
              <span>Platform Game ID: </span>
              <span>{{ item.platform_game_id }}</span>
            </div>
          </div>
          <div class="release-data">
            <span>Release Date: </span>
            <span>{{ item.release_date }}</span>
          </div>
        </div>
      </div>
      <!-- GAME INFO ================================================ END -->

      <!-- GAME DOCUMENTS SLIDER ======================================== -->
      <div class="_container">
        <DocumentsSlider :documents="documents" />
      </div>
      <!-- GAME DOCUMENTS SLIDER ==================================== END -->

      <!-- GAME ANCHORS ================================================= -->
      <div v-if="hasAnchors" class="_container">
        <div class="offset-section anchors-section">
          <div class="anchors-section__title">
            <span>JUMP TO:</span>
          </div>
          <div class="anchors-section__links">
            <a href="#description-anchor"
               @click="onAnchorClick">Description</a>
            <a v-if="hasFeatures"
               href="#feature-anchor"
               @click="onAnchorClick">Game Features</a>
            <a v-if="hasPromoToolsInfo"
               href="#promo-tools-anchor"
               @click="onAnchorClick">Gifts</a>
            <a v-if="hasMathDetailsInfo"
               href="#math-anchor"
               @click="onAnchorClick">Math</a>
            <a v-if="hasPaytableImages"
               href="#paytable-anchor"
               @click="onAnchorClick">Paytable</a>
            <a v-if="isPlayable"
               href="#runner-anchor"
               @click="onAnchorClick">Try this game</a>
          </div>
        </div>
      </div>
      <!-- GAME ANCHORS ============================================= END -->

      <!-- DESCRIPTION ================================================== -->
      <div class="_container" id="_description-anchor">
        <div class="offset-section description-section">
          <h6 class="offset-title">DESCRIPTION</h6>
          <div class="_container-description _container-description_reverse">
            <div class="column">
              <div class="_text-box" :class="{ 'expanded': isGameDescriptionBlockExpanded }" ref="gameDescriptionTextBox">
                <div v-html="item.description" ref="gameDescription"></div>
              </div>
              <a href="" class="page-button" v-if="!isGameDescriptionBlockExpanded"
                @click.prevent="onGameDescriptionExpandButtonClick">
                <div></div>
                <div>READ MORE</div>
                <div></div>
              </a>
            </div>
            <div class="column">
              <div class="description-section__card _ibg _with-bg">
                <img :src="item.logo_file" alt="kendoo">
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- DESCRIPTION ============================================== END -->

      <!-- GENERAL INFORMATION ========================================== -->
      <MoveLine :title="'GENERAL INFORMATION'">
        <span>SPECIFICATIONS</span>
        <span><img src="@/assets/img/svg/move-1.svg" alt="kendoo"></span>
        <span>SPECIFICATIONS</span>
        <span><img src="@/assets/img/svg/move-2.svg" alt="kendoo"></span>
        <span>SPECIFICATIONS</span>
        <span><img src="@/assets/img/svg/move-3.svg" alt="kendoo"></span>
      </MoveLine>
      <div class="_container">
        <div class="offset-section">
          <h6 class="print-only offset-title new-page">GENERAL INFORMATION</h6>
          <table class="params-table">
            <tr v-if="item.game_id !== undefined">
              <td>Game ID</td>
              <td>{{ item.game_id || '-' }}</td>
            </tr>
            <tr v-if="item.platform_game_id !== undefined">
              <td>Platform Game ID</td>
              <td>{{ item.platform_game_id || '-' }}</td>
            </tr>
            <tr>
              <td>Clean Up Date</td>
              <td>{{ item.clean_up_date || '-' }}</td>
            </tr>
            <tr>
              <td>Game Title (English)</td>
              <td>{{ item.title_text || '-' }}</td>
            </tr>
            <tr>
              <td>Game Title (Simplified Chinese)</td>
              <td>{{ item.title_zh || '-' }}</td>
            </tr>
            <tr>
              <td>Game Title (Traditional Chinese)</td>
              <td>{{ item.title_zh_hant || '-' }}</td>
            </tr>
            <tr>
              <td>Game Title (Thai)</td>
              <td>{{ item.title_th || '-' }}</td>
            </tr>
          </table>
        </div>
      </div>
      <!-- GENERAL INFORMATION ====================================== END -->

      <!-- GAME DETAILS ================================================= -->
      <div class="_container">
        <div class="offset-section">
          <h6 class="offset-title">GAME <span>DETAILS</span></h6>
          <table class="params-table">
            <tr v-if="item.game_type !== undefined">
              <td>Game Type</td>
              <td>{{ item.game_type || '-' }}</td>
            </tr>
            <tr v-if="item.game_category !== undefined">
              <td>Game Category</td>
              <td>{{ item.game_category || '-' }}</td>
            </tr>
            <tr v-if="item.screen_resolution !== undefined">
              <td>Screen Resolution</td>
              <td>{{ item.screen_resolution || '-' }}</td>
            </tr>
            <tr v-if="item.lines !== undefined">
              <td>Lines</td>
              <td>{{ item.lines || '-' }}</td>
            </tr>
            <tr v-if="item.board !== undefined">
              <td>Board</td>
              <td>{{ item.board || '-' }}</td>
            </tr>
            <tr v-if="item.specification !== undefined">
              <td>Specification</td>
              <td>
                {{
                  item.specification && item.specification.length > 0
                      ? item.specification.join(', ') : '-'
                }}
              </td>
            </tr>
            <tr v-if="item.free_spins_mode !== undefined">
              <td>Free Spins Mode</td>
              <td>{{ item.free_spins_mode ? 'Yes' : 'No' }}</td>
            </tr>
            <tr v-if="item.bonus_game_mode !== undefined">
              <td>Bonus Game Mode</td>
              <td>{{ item.bonus_game_mode ? 'Yes' : 'No' }}</td>
            </tr>
            <tr v-if="item.has_buy_feature !== undefined">
              <td>Buy Bonus</td>
              <td>{{ item.has_buy_feature ? 'Yes' : 'No' }}</td>
            </tr>
            <tr v-if="item.jackpot_type !== undefined">
              <td>Jackpot Type</td>
              <td>{{ item.jackpot_type || '-' }}</td>
            </tr>
            <tr>
              <td>Supported languages</td>
              <td>
                {{
                  item.languages ? item.languages.map(
                      ({code}) => `${code}`).join(', ') : '-'
                }}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <!-- GAME DETAILS ============================================= END -->

      <!-- BET SETTINGS ================================================= -->
      <div class="_container">
        <div class="offset-section">
          <h6 class="offset-title new-page">BET <span>SETTINGS</span></h6>
          <table class="params-table">
            <tr v-if="item.bet_factor !== undefined">
              <td>Bet Factor</td>
              <td>{{ item.bet_factor || '-' }}</td>
            </tr>
            <tr v-if="item.min_bet !== undefined">
              <td>Min Bet</td>
              <td :class="{'symbol-euro': item.min_bet }">
                {{ item.min_bet || '-' }}
              </td>
            </tr>
            <tr v-if="item.default_bet !== undefined">
              <td>Default Bet</td>
              <td :class="{'symbol-euro': item.default_bet }">
                {{ item.default_bet || '-' }}
              </td>
            </tr>
            <tr v-if="item.max_bet !== undefined">
              <td>Max Bet</td>
              <td :class="{'symbol-euro': item.max_bet }">
                {{ item.max_bet || '-' }}
              </td>
            </tr>
            <tr>
              <td>Supported currencies</td>
              <td>ALL (<a href="https://www.xe.com/" target="_blank">xe.com</a>) + cryprocurrencies (<a href="https://www.coinapi.io/" target="_blank">coinapi.io</a>)</td>
            </tr>
            <tr v-if="item.default_math !== undefined">
              <td>Max Exposure (TB)</td>
              <td>{{ item.default_math.tb || '-' }}</td>
            </tr>
            <tr v-if="item.default_math !== undefined">
              <td>Volatility</td>
              <td>{{ item.default_math.volatility || '-' }}</td>
            </tr>
          </table>
        </div>
      </div>
      <!-- BET SETTINGS ============================================= END -->

      <!-- GAME FEATURES ================================================ -->
      <div v-if="hasFeatures" id="_feature-anchor" class="news-geometry">
        <div class="news-geometry__angled_background"></div>
        <div class="_container">
          <h6 class="offset-title">GAME <span>FEATURES</span></h6>
          <GameFeaturesSlider :item="item"/>
        </div>
      </div>
      <!-- GAME FEATURES ============================================ END -->

      <!-- SLIDER SCREENSHOTS =========================================== -->
      <div v-if="hasGalleryImages">
        <MoveLine :title="'GALLERY'">
          <span>SCREENSHOTS</span>
          <span><img src="@/assets/img/svg/move-1.svg" alt="kendoo"></span>
          <span>SCREENSHOTS</span>
          <span><img src="@/assets/img/svg/move-2.svg" alt="kendoo"></span>
          <span>SCREENSHOTS</span>
          <span><img src="@/assets/img/svg/move-3.svg" alt="kendoo"></span>
        </MoveLine>
        <div class="offset-section_small">
          <div class="print-only _container">
            <h6 class="offset-title new-page">SCREENSHOTS</h6>
          </div>
          <ScreenshotSlider :images="item.gallery_images"/>
        </div>
      </div>
      <div v-if="!hasGalleryImages" class="offset-section"></div>
      <!-- SLIDER SCREENSHOTS ======================================= END -->

      <!-- RUNNER ======================================================= -->
      <div v-if="isPlayable" id="_runner-anchor" class="_container">
        <div class="no-print offset-section">
          <h6 class="offset-title">Try <span>the</span> game</h6>
          <div class="first-screen__center">
            <GameRunner
                :banner="item.banner_file"
                :languages="item.languages"
                :gameUrl="item.game_url"
                :lang="this.$route.query.lang"
            />
          </div>
        </div>
      </div>
      <!-- RUNNER =================================================== END -->

      <!-- PAYTABLE ===================================================== -->
      <div v-if="hasPaytableImages" id="_paytable-anchor">
        <MoveLine :title="'PAYTABLE'">
          <span>PAYTABLE</span>
          <span><img src="@/assets/img/svg/move-1.svg" alt="kendoo"></span>
          <span>PAYTABLE</span>
          <span><img src="@/assets/img/svg/move-2.svg" alt="kendoo"></span>
          <span>PAYTABLE</span>
          <span><img src="@/assets/img/svg/move-3.svg" alt="kendoo"></span>
        </MoveLine>
        <div class="offset-section_small">
          <div class="print-only _container">
            <h6 class="offset-title new-page">PAYTABLE</h6>
          </div>
          <ScreenshotSlider :images="item.paytable_images"/>
        </div>
      </div>
      <!-- PAYTABLE ================================================= END -->

      <!-- ADDITIONAL INFORMATION ======================================= -->
      <div v-if="hasAdditionalInfo" class="_container">
        <div class="offset-section">
          <h6 class="offset-title full-width new-page">
            ADDITIONAL <span>INFORMATION</span>
          </h6>
          <table class="params-table">
            <tr v-if="item.has_mobile !== undefined">
              <td>Mobile</td>
              <td>{{ item.has_mobile ? 'Yes' : 'No' }}</td>
            </tr>
            <tr v-if="item.has_replay !== undefined">
              <td>Replay</td>
              <td>{{ item.has_replay ? 'Yes' : 'No' }}</td>
            </tr>
            <tr v-if="item.has_spectator !== undefined">
              <td>Spectator</td>
              <td>{{ item.has_spectator ? 'Yes' : 'No' }}</td>
            </tr>
            <tr v-if="item.has_history !== undefined">
              <td>History</td>
              <td>{{ item.has_history ? 'Yes' : 'No' }}</td>
            </tr>
            <tr v-if="item.has_quick_spin !== undefined">
              <td>Quick Spin button available</td>
              <td>{{ item.has_quick_spin ? 'Yes' : 'No' }}</td>
            </tr>
          </table>
        </div>
      </div>
      <!-- ADDITIONAL INFORMATION =================================== END -->

      <!-- PROMO TOOLS SUPPORT ========================================== -->
      <div v-if="hasPromoToolsInfo" id="_promo-tools-anchor" class="_container">
        <div class="offset-section">
          <h6 class="offset-title full-width">
            PROMO <span>TOOLS</span> SUPPORT
          </h6>
          <table class="params-table">
            <tr v-if="item.bet_factor !== undefined">
              <td>Bet Factor</td>
              <td>{{ item.bet_factor || '-' }}</td>
            </tr>
            <tr v-if="item.has_feature_fixed_fb !== undefined">
              <td>Fixed FB</td>
              <td>{{ item.has_feature_fixed_fb ? 'Yes' : 'No' }}</td>
            </tr>
            <tr v-if="item.has_feature_flexible_fb !== undefined">
              <td>Flexible FB</td>
              <td>{{ item.has_feature_flexible_fb ? 'Yes' : 'No' }}</td>
            </tr>
            <tr v-if="item.has_feature_fs !== undefined">
              <td>Feature Free Spins</td>
              <td>{{ item.has_feature_fs ? 'Yes' : 'No' }}</td>
            </tr>
            <tr v-if="item.feature_fs_avg !== undefined">
              <td>Feature FS, Win Avg (TB)</td>
              <td>{{ item.feature_fs_avg || '-' }}</td>
            </tr>
            <tr v-if="item.feature_fs_90 !== undefined">
              <td>Feature FS, Win 90% (TB)</td>
              <td>{{ item.feature_fs_90 || '-' }}</td>
            </tr>
          </table>
        </div>
      </div>
      <!-- PROMO TOOLS SUPPORT ====================================== END -->

      <!-- MATH DETAILS ================================================= -->
      <div v-if="hasMathDetailsInfo" id="_math-anchor" class="_container">
        <div class="offset-section prize-table-section">
          <h6 class="offset-title full-width new-page">
            MATH <span>DETAILS</span>
          </h6>
          <div class="prize-table-container">
            <table class="prize-table">
              <tr class="top-line">
                <th></th>
                <th v-if="mathData.head.default"></th>
                <th v-if="mathData.head.lowest">LOWEST</th>
                <th v-if="mathData.head.low">LOW</th>
                <th v-if="mathData.head.high">HIGH</th>
              </tr>
              <tr class="table-line border-r">
                <td>RTP, %</td>
                <td v-if="mathData.rtp.default">
                  {{ mathData.rtp.default }}
                </td>
                <td v-if="mathData.rtp.lowest">
                  {{ mathData.rtp.lowest }}
                </td>
                <td v-if="mathData.rtp.low">
                  {{ mathData.rtp.low }}
                </td>
                <td v-if="mathData.rtp.high">
                  {{ mathData.rtp.high }}
                </td>
              </tr>
              <tr class="table-line">
                <td>Volatility</td>
                <td v-if="mathData.volatility.default">
                  {{ mathData.volatility.default }}
                </td>
                <td v-if="mathData.volatility.lowest">
                  {{ mathData.volatility.lowest }}
                </td>
                <td v-if="mathData.volatility.low">
                  {{ mathData.volatility.low }}
                </td>
                <td v-if="mathData.volatility.high">
                  {{ mathData.volatility.high }}
                </td>
              </tr>

              <tr class="table-line">
                <td>Deviation of RTP, %</td>
                <td v-if="mathData.rtp_deviation.default">
                  {{ mathData.rtp_deviation.default }}
                </td>
                <td v-if="mathData.rtp_deviation.lowest">
                  {{ mathData.rtp_deviation.lowest }}
                </td>
                <td v-if="mathData.rtp_deviation.low">
                  {{ mathData.rtp_deviation.low }}
                </td>
                <td v-if="mathData.rtp_deviation.high">
                  {{ mathData.rtp_deviation.high }}
                </td>
              </tr>

              <tr class="table-line">
                <td>Max Exposure (TB)</td>
                <td v-if="mathData.tb.default">
                  {{ mathData.tb.default }}
                </td>
                <td v-if="mathData.tb.lowest">
                  {{ mathData.tb.lowest }}
                </td>
                <td v-if="mathData.tb.low">
                  {{ mathData.tb.low }}
                </td>
                <td v-if="mathData.tb.high">
                  {{ mathData.tb.high }}
                </td>
              </tr>

              <tr class="table-line">
                <td>Main Game Hit Freq, %</td>
                <td v-if="mathData.main_game_hit_freq.default">
                  {{ mathData.main_game_hit_freq.default }}
                </td>
                <td v-if="mathData.main_game_hit_freq.lowest">
                  {{ mathData.main_game_hit_freq.lowest }}
                </td>
                <td v-if="mathData.main_game_hit_freq.low">
                  {{ mathData.main_game_hit_freq.low }}
                </td>
                <td v-if="mathData.main_game_hit_freq.high">
                  {{ mathData.main_game_hit_freq.high }}
                </td>
              </tr>

              <tr class="table-line">
                <td>Free Game Hit Freq, %</td>
                <td v-if="mathData.free_game_hit_freq.default">
                  {{ mathData.free_game_hit_freq.default }}
                </td>
                <td v-if="mathData.free_game_hit_freq.lowest">
                  {{ mathData.free_game_hit_freq.lowest }}
                </td>
                <td v-if="mathData.free_game_hit_freq.low">
                  {{ mathData.free_game_hit_freq.low }}
                </td>
                <td v-if="mathData.free_game_hit_freq.high">
                  {{ mathData.free_game_hit_freq.high }}
                </td>
              </tr>

              <tr class="table-line">
                <td>Main Game RTP, %</td>
                <td v-if="mathData.main_game_rtp.default">
                  {{ mathData.main_game_rtp.default }}
                </td>
                <td v-if="mathData.main_game_rtp.lowest">
                  {{ mathData.main_game_rtp.lowest }}
                </td>
                <td v-if="mathData.main_game_rtp.low">
                  {{ mathData.main_game_rtp.low }}
                </td>
                <td v-if="mathData.main_game_rtp.high">
                  {{ mathData.main_game_rtp.high }}
                </td>
              </tr>

              <tr class="table-line">
                <td>Free Game RTP, %</td>
                <td v-if="mathData.free_game_rtp.default">
                  {{ mathData.free_game_rtp.default }}
                </td>
                <td v-if="mathData.free_game_rtp.lowest">
                  {{ mathData.free_game_rtp.lowest }}
                </td>
                <td v-if="mathData.free_game_rtp.low">
                  {{ mathData.free_game_rtp.low }}
                </td>
                <td v-if="mathData.free_game_rtp.high">
                  {{ mathData.free_game_rtp.high }}
                </td>
              </tr>

            </table>
          </div>
        </div>

      </div>
      <!-- MATH DETAILS ============================================= END -->

      <!-- RANDOM NUMBER GENERATOR ====================================== -->
      <div class="_container">
        <div class="offset-section">
          <h6 class="offset-title full-width new-page">RANDOM <span>NUMBER</span> GENERATOR</h6>
          <div class="_container-description _text-box"
               v-html="item.rng_text"></div>
        </div>
      </div>
      <!-- RANDOM NUMBER GENERATOR ================================== END -->

      <!-- MALFUNCTION MANAGEMENT ======================================= -->
      <div class="_container">
        <div class="offset-section">
          <h6 class="offset-title full-width new-page">MALFUNCTION <span>MANAGEMENT</span></h6>
          <div class="section-container section-container_second">
            <div class="_text-box">
              <p>
                If a player attempts to access a game using an invalidated session,
                (for example, due to inactivity or system restart) an error message
                is displayed in the game. The player can then log on again and
                resume game play. The game state is always stored so the player can
                resume game play exactly where he or she left off.
                In case of an HTTP error, network timeout or server error a message
                about technical error will be displayed to the player.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- MALFUNCTION MANAGEMENT =================================== END -->

    </div>
  </main>
</template>


<script>
import {appFetchJSON, processFetchError} from '@/lib/request_utils';

import BreadCrumbs from '@/components/breadcrumbs';
import DocumentsSlider from '@/components/documents_slider';
import MoveLine from '@/components/move_line';
import NewsSlider from '@/components/news_slider';
import ScreenshotSlider from '@/components/screenshot_slider';
import GameRunner from '@/components/game_runner';
import GameFeaturesSlider from './game_features_slider';


export default {
    name: 'GameForAuthUser',
    components: {
        BreadCrumbs,
        DocumentsSlider,
        GameFeaturesSlider,
        ScreenshotSlider,

        GameRunner,
        MoveLine,
        NewsSlider
    },

    data() {
        return {
            item: {},
            documents: [],
            isGameDescriptionBlockExpanded: false,
        }
    },

    created() {
        window.addEventListener("resize", this.onResize.bind(this));
    },

    destroyed() {
        window.removeEventListener("resize", this.onResize.bind(this));
    },

    mounted() {
        this.getGame().then(() => {
            this.updateExpandableDescriptionState();
        });
        this.fetchDocuments();
    },

    computed: {

        gameName() {
            return this.$route.params.name;
        },

        hasAnchors() {
            return this.hasFeatures
                || this.hasPromoToolsInfo
                || this.hasMathDetailsInfo
                || this.hasPaytableImages
                || this.isPlayable;
        },

        hasFeatures() {
            return this.item.features
                && this.item.features.length > 0;
        },

        hasPromoToolsInfo() {
            return this.item.bet_factor !== undefined
                || this.item.has_feature_fixed_fb !== undefined
                || this.item.has_feature_flexible_fb !== undefined
                || this.item.has_feature_fs !== undefined
                || this.item.feature_fs_avg !== undefined
                || this.item.feature_fs_90 !== undefined;
        },

        hasMathDetailsInfo() {
            return this.item.default_math !== undefined
                || this.item.lowest_math !== undefined
                || this.item.low_math !== undefined
                || this.item.high_math !== undefined;
        },

        hasPaytableImages() {
            return this.item.paytable_images
                && this.item.paytable_images.length > 0;
        },

        isPlayable() {
            return this.item.is_playable;
        },

        hasGalleryImages() {
            return this.item.gallery_images
                && this.item.gallery_images.length > 0;
        },

        hasAdditionalInfo() {
            return this.item.has_mobile !== undefined
                || this.item.has_replay !== undefined
                || this.item.has_spectator !== undefined
                || this.item.has_history !== undefined
                || this.item.has_quick_spin !== undefined;
        },

        mathData() {
            let res = {head: {}};
            let fillValue = (obj, name) => {
                res['head'][name] = true;
                for (let key in obj) {
                    if (!res[key]) {
                        res[key] = {};
                    }
                    res[key][name] = obj[key] || '-';
                }
            }

            if (this.item.default_math !== undefined) {
                fillValue(this.item.default_math, 'default');
            }
            if (this.item.high_math !== undefined) {
                fillValue(this.item.high_math, 'high');
            }
            if (this.item.low_math !== undefined) {
                fillValue(this.item.low_math, 'low');
            }
            if (this.item.lowest_math !== undefined) {
                fillValue(this.item.lowest_math, 'lowest');
            }
            return res
        }
    },

    methods: {
        getGame() {
            return appFetchJSON(`/games/${this.gameName}`)
                .then(res => {
                    this.item = res.data;
                    if (window.location.hash) {
                        setTimeout(() => {
                            if (window.location.hash === '#print') {
                                this.getPdfDoc();
                                return;
                            }
                            this.gotoAnchor(window.location.hash.slice(1))
                            history.replaceState(
                                {}, document.title, window.location.href.split('#')[0]);
                        }, 0)
                    }
                })
                .catch(err => {
                    processFetchError(this.$router, err);
                });
        },

        fetchDocuments() {
            return appFetchJSON(
                `/games/${this.gameName}/documents`,
                {method: 'GET'}
            ).then(res => {
                const documents = res.data || [];
                documents.sort((a, b) => {
                    if (a.type === 'game_guide') return -1;
                    if (b.type === 'game_guide') return 1;
                    if (a.type === b.type && a.type === 'game_guide') return 0;
                    if (a.title > b.title) return 1;
                    if (a.title < b.title) return -1;
                    return 0;
                })
                this.documents = documents;
            }).catch(res => {
                console.log('------------ TODO GET DOCUMENTS HANDLE ERROR!', res);
            });
        },

        onAnchorClick(e) {
            let hash = e.target.hash.slice(1);
            this.gotoAnchor(hash);
        },

        gotoAnchor(hash) {
            let el = this.$el.querySelector('#_' + hash)
            if (!el) {
                return;
            }
            let rect = el.getBoundingClientRect()
            setTimeout(() => {
                window.scrollTo({
                    top: rect.top + window.pageYOffset,
                    behavior: 'smooth'
                });
            }, 50);
        },

        onGameDescriptionExpandButtonClick() {
            this.isGameDescriptionBlockExpanded = !this.isGameDescriptionBlockExpanded;
        },

        onResize() {
            if (this.resizeTimeout) {
                clearInterval(this.resizeTimeout);
            }
            this.resizeTimeout = setTimeout(() => {
                this.resizeTimeout = undefined;
                this.updateExpandableDescriptionState();
            }, 600);
        },

        updateExpandableDescriptionState() {
            if (this.isGameDescriptionBlockExpanded) return;
            this.$nextTick(() => {
                const gameDescriptionRef = this.$refs.gameDescription;
                const gameDescriptionTextBoxRef = this.$refs.gameDescriptionTextBox;
                const gameDescriptionRefRect = gameDescriptionRef.getBoundingClientRect();
                const gameDescriptionTextBoxRefRect = gameDescriptionTextBoxRef.getBoundingClientRect();
                if (gameDescriptionRefRect.height <= gameDescriptionTextBoxRefRect.height) {
                    this.isGameDescriptionBlockExpanded = true;
                }
            })
        },

        /*
        getPdfDoc() {
            document.body.style.cursor = 'wait';
            appFetch(`/games/${this.$route.params.name}`, {
                method: 'GET',
                params: {
                    print: true
                }
            }).then(
                res => res.blob()
            ).then( blob => {
                history.replaceState(
                    {}, document.title, window.location.href.split('#')[0]);
                let url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.target = '_blank';
                a.download = `${this.$route.params.name}_game_gide.pdf`;
                a.click();
            }).then(() => {
                document.body.style.cursor = 'default';
            });
        },*/
    },

}
</script>

