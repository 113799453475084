<template>
    <section class="prize-pool-section">
        <span class="prize-pool-section__title">PRIZEPOOL</span>
        <span class="prize-pool-section__value">
            {{ tournament?.prize_pool_banner_text }}
        </span>
    </section>
</template>

<script>
export default {
    name: "TournamentCommonPrizePoolSection",
    props: {
        tournament: {},
    },
}
</script>

